import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Home';
import Blog from './Blog';
import CGT270 from './CGT270';
import Projects from './Projects';
import Misc from './Misc';
import Robotics from './Robotics';
import Hextraction from './Hextraction';
import NotFound from './NotFound';

export default function Main() {
  return (
    <Routes>
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/Blog' element={<Blog/>}></Route>
      <Route exact path='/Projects' element={<Projects/>}></Route>
      <Route exact path='/Misc' element={<Misc/>}></Route>
      <Route exact path='/CGT270' element={<CGT270/>}></Route>
      <Route exact path='/Robotics' element={<Robotics/>}></Route>
      <Route exact path='/Hextraction' element={<Hextraction/>}></Route>
      <Route path='*' element={<NotFound/>}></Route>
    </Routes>
  );
}
