import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
return (
<section>
    <div className="md:mx-10 mt-5">
    <div className= "mt-10 mb-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">
        <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white ">
            Andrew Thompson
        </h1>
      </div>
        <div className=" mb-10 container mx-auto flex flex-col lg:flex-row place-content-center space-x-10">
            <div className= "bg-gray-800 bg-opacity-90 p-4 pb-2 rounded-xl h-1/2 lg:w-3/4 w-full text-center my-auto">
            <h2 className="text-3xl font-bold text-highlight mb-2">
                - About Me -
            </h2>
            <p className="text-2xl mb-4 text-gray-300 text-left px-4 w-full">
                Hello and welcome to my corner of the internet! I'm Andrew, a Purdue CS & DS student in the class of 2026. 
                Here you can find information like my resume and contact info, along with some examples of projects I've done both 
                in and out of the classroom.
            </p>
            </div>
            <center>
            <img 
            className = "w-1/2 md:w-1/3 lg:w-1/2 outline-main rounded-2xl object-scale-down max-h-full mx-auto lg:mr-auto lg:ml-10 my-5"
            src = "/headshot.png"
            alt = "Headshot"
            />
            </center>
        </div>

    <p className="bg-highlight-lime lg:w-1/2 md:w-3/4 w-full mx-auto text-center rounded-xl text-2xl font-bold py-2 mt-10 bg-opacity-70 break-words">
       {'<'} Contact me at andrewtho5942@gmail.com {'>'}
    </p>

      <div className="flex flex-col md:flex-row space-y-10 md:space-y-0 md:space-x-10 my-10 place-content-center">
        <div className="hover:opacity-100 opacity-80 transform transition duration-300 hover:scale-105">
        <a href="/Resume.pdf" target="_blank" rel="noopener noreferrer">
                <div className="mb-4 flex flex-row space-x-2 place-content-center ">
                <img
                className="object-cover object-center h-12 w-12"
                alt="icon"
                src="./Resume.svg"
                />
                <p className="text-white text-4xl font-semibold mt-1 flex place-content-center">
                    Resume
                    <img src="/arrow_out.svg" alt="a_out" className="h-5 w-5 ml-1 mr-3 stroke-icon"/>
                </p>
                </div>
                
                <img 
                className = "mx-auto md:w-64 h-64 object-contain rounded-lg"
                src = "/resume.png"
                alt = "Resume"
                />
    
            </a>
        </div>
        
        <div className="hover:opacity-100 opacity-80 transform transition duration-300 hover:scale-105">
        <Link to="/Projects">
            <div className="mb-4 flex flex-row space-x-2 place-content-center">
            <img
            className="object-cover object-center h-12 w-12"
            alt="icon"
            src="./Projects.svg"
            />
            <p className="text-white text-4xl font-semibold mt-1">
                Projects
            </p>
            </div>
            
            <img 
            className = "mx-auto md:w-64 h-64 object-contain rounded-lg"
            src = "/SequenceTest.png"
            alt = "Project example"
            />
            </Link>
        </div>

        <div className="hover:opacity-100 opacity-80 transform transition duration-300 hover:scale-105">
        <Link to="/Misc">
            <div className="mb-4 flex flex-row space-x-2 place-content-center">
            <img
            className="object-cover object-center h-12 w-12"
            alt="icon"
            src="./Classwork.svg"
            />
            <p className="text-white text-4xl font-semibold mt-1">
                Miscellaneous
            </p>
            </div>
            <center>
            <img 
            className = "mx-auto md:w-64 h-64 object-contain rounded-lg"
            src = "/cs180_gui.png"
            alt = "Classwork example"
            />
            </center>
            </Link>
        </div>
      </div>
       </div>
       <div className="">
          <h1 className="mx-auto p-8 bg-gray-800 bg-opacity-70 text-white"> 
            Built with React & Tailwind - hosted for free by Netlify.
          </h1>
        </div>
</section>
    
);
}