import React from "react"

// firebase imports
import { initializeApp } from 'firebase/app';
import { getFirestore, onSnapshot, collection } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { useState, useEffect } from 'react';

const BlogPost = ({ title, caption, imageLinks, timestamp }) => {
    // process timestamp
    const date = new Date(timestamp);
    // Convert to EST (UTC-5 or UTC-4 depending on daylight saving time)
    const options = {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return (
        <section className="md:mx-10 mt-5">   
        <div>
            <div className="bg-highlight-lime opacity-70 mt-10 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                {title}
                <p className="text-2xl text-green-900">
                    {formattedDate}
                </p>
            </div>
            <div className="bg-gray-800 rounded-xl p-6 bg-opacity-90">
            <p className="text-white text-left text-2xl mb-6 mt-6 lg:w-3/4 mx-auto">
                    {caption}
                </p>
                
                <div className="blog-container flex-wrap mx-auto place-content-center">
                    {imageLinks.map((src, index) => (
                        <img
                            className="rounded-xl h-60 outline-light"
                            key={index}
                            src={src}
                            alt={`${index + 1}`} />
                    ))}
                </div>
            </div>
        </div>
</section>
    );
}


export default function Blog() {
    const [blogPosts, setBlogPosts] = useState([]);
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    const db = getFirestore(app);
    const auth = getAuth(app);
    const blogCollection = collection(db, 'blog');

    


    useEffect(() => {
        let unsubscribe = null
        signInAnonymously(auth).then(() => {
            // Set up the snapshot listener for the 'blog' collection
            unsubscribe = onSnapshot(blogCollection, (snapshot) => {
                // update blogPosts with the most recent data pulled from firebase
                let posts = []
                snapshot.forEach((doc) => {
                    const data = doc.data()
                    posts.push({
                        title: data.title,
                        caption: data.caption,
                        links: data.links,
                        timestamp: data.timestamp
                    })
                });
                posts.sort((a, b) => b.timestamp - a.timestamp);

                // set posts to the useState variable blogPosts
                setBlogPosts(posts);
            });

        }) 

        
        // Clean up the listener on component unmount
        return () => unsubscribe();
    }, [db]);

    return (
        <section>
            {blogPosts.length === 0 ? (
                <div>
                    <div style={{ height: "20vh" }}></div>
                    <div className="my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">
                        <h1 className="text-6xl mb-4 font-bold text-red-500">
                            No blog posts were found <span role="img" aria-label="shocked emoji">😧</span>!
                        </h1>
                        <p className="text-2xl mb-4 font-semibold text-gray-400">
                            The database connection might not be working. Please check again later.
                        </p>
                    </div>
                    <div style={{ height: "50vh" }}></div>

                </div>

            ) : (
                blogPosts.map((post) => (
                    <BlogPost
                        key={post.timestamp}
                        title={post.title}
                        caption={post.caption}
                        imageLinks={post.links}
                        timestamp={post.timestamp}
                    />
                ))
            )}
            <div style={{ height: "20vh" }}></div>
        </section>
    );
}
