import React from "react"

export default function Misc() {

    return (
        <section className="md:mx-10 mt-5">
            <div className="my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">
                <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white">
                    Miscellaneous
                </h1>
            </div>
            <section id ="brgt-website"/>
            <div className="my-4 text-2xl mb-4 font-semibold text-gray-300 bg-gray-800 bg-opacity-90 p-4 rounded-xl lg:w-3/4 w-full mx-auto text-center">
                This is for any classwork or projects of mine that don't have their own dedicated page.
            </div>
                <div className="bg-highlight-lime opacity-70 mt-10 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                    Boilermaker Rube Goldberg Website
                </div>
                <div className="bg-gray-800 rounded-xl bg-opacity-90 py-6 text-center">
                    <p className="text-white text-2xl mx-auto mb-4 lg:w-2/3 text-center px-4">
                        &nbsp;&nbsp;&nbsp;&nbsp;I am the webmaster of the boilermaker rube goldberg team, where we create a 
                        rube goldberg machine every year with a different theme and objective. Being the webmaster means I am responsible for
                        maintaining and improving the website. This year, I've already migrated the website to use the React framework. It's linked below!
                    </p>
                    <div className="w-5/6 md:w-2/3 lg:w-1/2 mb-4 mt-2 mx-auto">
                    <a href="https://boilermakerrubegoldberg.com/" >
                        <img 
                            className="rounded-xl w-full transform transition duration-300 hover:scale-105 overflow-hidden outline-light opacity-80 hover:opacity-100"
                            src="brgt.png"
                            alt="BRGT website"
                        />
                    </a>
                    </div>
                    <section id ="print-manager"/>

                </div>

            <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                Print Manager
            </div>
            <div className="bg-gray-800 rounded-xl bg-opacity-90 py-6 text-center">
                <p className="text-white text-2xl mx-auto mb-4 lg:w-2/3 text-center px-4">
                    &nbsp;&nbsp;&nbsp;&nbsp;This project was done in April 2024 for CS348 "Information Systems". It is a full-stack MERN web app that allows the user to view and manage a 3D-printing lab.
                    For those not familiar with 3D-printing, filament is the plastic material that is inserted into the printer for it to use, and GCODE files
                    are the instructions uploaded to the printer to create a certain object.
                    <br></br><br></br>
                    The app has a summary page to view data visualizations covering the lab's print history and filament usage. The printers are visible in a list on the
                    left sidebar, along with their status. If a printer is clicked, it will open a page containing multiple buttons that perform actions specific to the printer.
                    The page also includes information about the printer, and its complete print history, containing details about every print the machine has done.
                    <br></br>
                    Clicking the "Open Print Menu" button opens a menu with every non-empty filament spool, and any spools in use will be greyed out and inaccessible.
                    After choosing a printer, filament spool, GCODE file, and filament usage, a print job can be started.
                </p>
                <div className="w-4/5 mx-auto my-10">
                    <iframe
                        title="yt-video"
                        className="mx-auto w-full rounded-2xl"
                        height="650"
                        src="https://www.youtube.com/embed/QOXt-kS5vuA?si=_-m8C8POttUAYvlT"
                        allow="fullscreen; web-share"
                    >
                    </iframe>
                </div>
                <p className="text-white text-2xl mx-auto mb-4 lg:w-2/3 text-center px-4">
                    Also, here is the ERD I created for the app's relational database schema. 
                </p>
                <img 
                    className="rounded-xl w-3/4 md:w-2/3 lg:w-1/3 mb-4 mt-2 mx-auto overflow-hidden outline-light"
                    src="printmanager_diagram.png"
                    alt="PrintManager ERD Diagram"
                />
                <div className="mx-auto text-center py-4">
                    <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/PrintManager">
                        &lt;Code for this project&gt;
                    </a>
                </div>
                <section id ="virtual-marketplace"/>

            </div>
            <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                Virtual Marketplace (CS 180)
            </div>
            <div className="bg-gray-800 rounded-xl bg-opacity-90 py-6 text-center">

                <p className="text-white text-2xl mx-auto mb-4 lg:w-2/3 text-center px-4">
                    &nbsp;&nbsp;&nbsp;&nbsp;This was done with three other students in a course covering object-oriented
                    programming in Java (Purdue CS180). It is a digital marketplace with the ability to act as a seller or customer.
                    My main contributions were the file I/O and multithreading. <br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;The project uses file I/O to read and write data to text
                    files that allows the marketplace to keep track of things like the available items (and their data) as well as account
                    usernames and passwords. The multithreading allows multiple clients to run simultaneously and connect to the server, including
                    update detection to notify customers of any changes in the marketplace since they last refreshed. Lastly, the GUI visually displays
                    the marketplace and available actions based on the user's role.
                </p>
                <img
                    alt="marketplace example"
                    src="/cs180_example.png"
                    className="rounded-xl lg:w-2/3 md:w-3/4 mx-auto px-4 my-10"
                />
                <div className="mx-auto text-center py-4">
                    <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/VirtualMarketplace">
                        &lt;Code for this project&gt;
                    </a>
                </div>
            </div>
            <div className="h-24" />
        </section>
    );
}