import React from "react"

export default function NotFound() {
    return (
    <section>  
        <div style={{height:"20vh"}}></div>
        <div className= "my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">    
            <h1 className="text-6xl mb-4 font-bold text-red-500">
                Sorry, this page does not exist!
            </h1>
            <p className="text-3xl mb-4 font-semibold text-gray-400">
                Perhaps it was entered incorrectly?
            </p>
        </div>
        <div style={{height:"50vh"}}></div>
        
    </section>
    );
}