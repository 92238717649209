import React from "react";

export default function CGT270() {
    return (
    <section className="md:mx-10 mt-5">   
    <div className= "my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">    
    <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white">
            CGT270 - Intro to Data Visualization
        </h1>
    </div>
    <section id="irish-language"/>
    <div className="my-4 text-2xl mb-4 font-semibold text-gray-300 bg-gray-800 bg-opacity-90 p-4 rounded-xl lg:w-3/4 w-full mx-auto text-center">
        This page shows the projects that I have done for a data visualization class that I took the Fall 2023 semester at Purdue. All of the visualizations shown here were
        made with R on RStudio using the ggplot2 package.
    </div>
    <div className="bg-highlight-lime opacity-70 mt-10 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        Irish Language Analysis (Individual)
    </div>
    <div className="bg-gray-800 rounded-xl px-6 py-6 bg-opacity-90">
        <img 
            className="rounded-xl lg:w-2/3 mx-auto mb-4 outline-light"
            src="/CGT270/IrishVisNew.png"
            alt="Visualization 1"
        />
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp;This multi-bar graph shows how the number of Irish speakers in Gaeltacht areas (areas where Irish is spoken more frequently) 
            has changed from 2011-2022. Clearly, the Irish government's efforts to teach kids Irish in school (around the ages of 5-19) is working, however,
             most are not retaining this skill once they get out of the education system, indicated by the drop in speakers after the age of 20. <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Also, the chart makes it clear that the older generation of Irish speakers is not being replaced,
             as shown by the sharp decline for ages 25-39 and the increase for ages 65-84.
        </p>
        <div className="flex flex-row space-x-4 place-content-center">
        <img 
            className="rounded-xl lg:w-1/2 w-3/4 mb-4 mt-2 outline-light"
            src="/CGT270/IrishTownsVis.png"
            alt="Visualization 2"
        />
    
        <iframe
            className="rounded-xl outline-main lg:w-1/4 w-1/2"
            title="Donegal County"
            src="https://www.google.com/maps/embed/v1/place?q=Mín+Lárach,+County+Donegal,+Ireland&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8&maptype=satellite&zoom=6&center=54,-7.95"
          />
        </div>
        
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mt-2 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp;This scatterplot compares the rate at which Irish is spoken daily with the population of the town for the ten 
            towns where Irish is spoken the most. Of these towns, all but two had a population of less than 500, six were in Donegal county, and 
            three were in Galway county. This indicates that Irish is spoken more frequently in small towns that are more likely to have a tight local community. 
        </p>
        <h1 className="text-highlight mx-auto font-semibold text-center text-4xl lg:w-1/2 w-2/3">
            Worksheet Links
        </h1>
        <div>
        <table className="border-collapse mx-auto text-white text-left table-auto my-4">
            <thead class="bg-gray-900">
                <tr>
                    <th class="border px-20 py-2 text-xl">Step</th>
                    <th class="border px-100 py-2 text-xl text-center">Link</th>
                </tr>
            </thead>
            <tbody class="text-lg font-semibold">
                <tr>
                    <td class="border mx-35 px-5 py-2">Acquire</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight"><a href="https://drive.google.com/file/d/1I5_LHGx-SPQtg2Kktr6tZxLB7wj8kNF8/view?usp=sharing"> 
                    https://drive.google.com/file/d/1I5_LHGx-SPQtg2Kktr6tZxLB7wj8kNF8/view?usp=sharing </a></td>
                </tr>
                <tr class="bg-gray-700 bg-opacity-60">
                    <td class="border mx-35 px-5 py-2">Parse</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight-bright"><a href="https://drive.google.com/file/d/1wsEPWL3WrokJGVMvVxGsXoC2BrkBHHC0/view?usp=sharing"> 
                    https://drive.google.com/file/d/1wsEPWL3WrokJGVMvVxGsXoC2BrkBHHC0/view?usp=sharing</a></td>
                </tr>
                <tr>
                    <td class="border mx-35 px-5 py-2">Mine</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight"><a href="https://drive.google.com/file/d/16JY-yon0jF-rvi8i4WkzntBaOVDEj-gv/view?usp=sharing"> 
                    https://drive.google.com/file/d/16JY-yon0jF-rvi8i4WkzntBaOVDEj-gv/view?usp=sharing </a></td>
                </tr>
                <tr class="bg-gray-700 bg-opacity-60">
                    <td class="border mx-35 px-5 py-2">Sketch</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight-bright"><a href="https://drive.google.com/file/d/1l-306_POZf2qNyBP0nL_mWN8VRKtcrD0/view?usp=sharing"> 
                    https://drive.google.com/file/d/1l-306_POZf2qNyBP0nL_mWN8VRKtcrD0/view?usp=sharing</a></td>
                </tr>
                <tr>
                    <td class="border mx-35 px-5 py-2">Filter</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight"><a href="https://drive.google.com/file/d/1U2BPl8Yg52fHN8kBN4ba_Hxj5r2E0m9m/view?usp=sharing"> 
                    https://drive.google.com/file/d/1U2BPl8Yg52fHN8kBN4ba_Hxj5r2E0m9m/view?usp=sharing </a></td>
                </tr>
                <tr class="bg-gray-700 bg-opacity-60">
                    <td class="border mx-35 px-5 py-2">Represent</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight-bright"><a href="https://drive.google.com/file/d/1ErKUxa_uC1A-19It0rMSEnJZ2P-bpep4/view?usp=sharing"> 
                    https://drive.google.com/file/d/1ErKUxa_uC1A-19It0rMSEnJZ2P-bpep4/view?usp=sharing </a></td>
                </tr>
                <tr>
                    <td class="border mx-35 px-5 py-2">Critique</td>
                    <td class="border mx-35 px-5 py-2 underline text-highlight"><a href="https://drive.google.com/file/d/1Svzob6hmKxzYFiuogICuXHGsVv3q75Tc/view?usp=sharing"> 
                    https://drive.google.com/file/d/1Svzob6hmKxzYFiuogICuXHGsVv3q75Tc/view?usp=sharing </a></td>
                </tr>
            </tbody>
        </table>
        <section id="decoding-purdue"/>
        </div>
    </div>

    <div class="h-10"> </div>

    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        Decoding Purdue (Group)
    </div>
    <div className="bg-gray-800 p-6 bg-opacity-90 rounded-xl">
        <p class="text-highlight text-center text-2xl mb-4 font-semibold">
            - Click the image below to be taken to this project's website! -
        </p>
        <div className="lg:w-1/2 w-3/4 mx-auto">
        <a href="https://party-of-four.netlify.app/">
        <img 
            className="rounded-xl mb-4 mt-2 mx-auto transform transition duration-300 hover:scale-105 overflow-hidden outline-light opacity-80 hover:opacity-100"
            src="/CGT270/cgt270-website.png"
            alt="Project Home Page"
        />
        </a>
        </div>
        
        
        
        <p class="text-gray-300 text-center text-2xl lg:w-2/3 mx-auto mb-4 font-semibold">
                    In this project, my main contribution was the group’s website, 
                    which is used to host a project summary including a synthesis of 
                    our findings, all our visualizations, and information like the team’s 
                    contributions & background. Besides this, however, I also made some visualizations 
                    of my own that show some insights into topics that I found particularly interesting,
                    including finding relationships between certain subjects or courses and their average GPA.
        </p>
        <section id="makeover-monday"/>
    </div>
    <div class="h-10"> </div>

    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        Makeover Monday Visualizations (Individual)
    </div>
    <div className="bg-gray-800 p-6 bg-opacity-90 rounded-xl">
    <img 
            className="rounded-xl lg:w-2/3 mb-4 mt-2 mx-auto"
            src="/CGT270/SpacewalksVisualization.png"
            alt="Visualization 1"
        />
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mt-2 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp;This visualization compares the number of ISS spacewalks between the USA and Russia from 1998 to 2019. The USA usually had more spacewalks per year
            than Russia, with the exception of years like 2004, 2013, and 2014. 
        </p>
        <img 
            className="rounded-xl lg:w-1/2 w-3/4 mb-4 mt-2 mx-auto"
            src="/CGT270/bobross1.png"
            alt="Visualization 2"
        />
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mt-2 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp; This visualization uses the frequency of each element in The Joy of Painting to reconstruct a statistically accurate
            Bob Ross painting, with the area of each element proportional to the relative frequency of the element in the show. The chart itself is a waffle chart with original Bob Ross 
            artwork overlaid on the divisions they represent.
        </p>
        <img 
            className="rounded-xl lg:w-2/3 mb-4 mt-2 mx-auto"
            src="/CGT270/bobross2.png"
            alt="Visualization 3"
        />
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mt-2 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp; This is a continuation of the Bob Ross analysis where a stacked bar chart is used to break down each season of 
            The Joy of Painting. 
        </p>
        <img 
            className="rounded-xl lg:w-2/3 mb-4 mt-2 mx-auto"
            src="/CGT270/UKExportsVisualization.png"
            alt="Visualization 4"
        />
        <p className="text-white text-left text-2xl mb-12 lg:w-3/4 mt-2 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp; This visualization analyzes changes in the UK's exports across multiple economic sectors between 2018 and 2020. Interestingly,
            exports of machinery and transport equipment fell by about 25% between 2019 and 2020, which coincides with Brexit (which happened in January 2020).
        </p>
    </div>
    </section>
    );
}
