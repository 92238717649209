import React from "react"

export default function Hextraction() {

    return (
        <section className="md:mx-10 mt-5">
            <div className="my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">
                <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white">
                    Hextraction
                </h1>
            </div>
            <div className="my-4 text-2xl mb-4 font-semibold text-gray-300 bg-gray-800 bg-opacity-90 p-4 rounded-xl lg:w-3/4 w-full mx-auto text-center">
                For my work on a 3D-printable board game called "Hextraction", made by a youtuber by the name of Zach Freedman. 
                <br/> <div className="text-highlight-lime mt-5">Here is a video explanation:</div>
                <iframe
                        title="yt-video"
                        className="mx-auto w-full rounded-2xl mt-5"
                        height="650"
                        src="https://www.youtube.com/embed/Iqz7ZDdP25A?si=lxU1id5JO4T4wzAp"
                        allow="fullscreen; web-share"
                    >
                    </iframe>
            <section id="custom-boards"/>
            </div>


            <div className="bg-highlight-lime opacity-70 mt-32 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                Custom Boards
            </div>
            <div className="bg-gray-800 rounded-xl bg-opacity-90 py-6 text-center text-gray-300 text-2xl p-4 px-8">
                <p className="w-full lg:w-3/4 mx-auto">
                First, I created full-board gutters that would funnel the balls to the bottom of the board for the regular and fancy boards that Zack Freedman made,
                 then I decided to create my own extended board that was covered on all sides with gutters. On this board, the ball collection is at the bottom
                between the endzones for convenience. 
                </p>
                
                <br/>
                <iframe src="https://gmail3353691.autodesk360.com/shares/public/SH30dd5QT870c25f12fcf1a1cab86e7e8a71?mode=embed"
                  allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                   frameborder="0" title="Hextraction Boards" height="600" className="md:w-2/3 w-full mx-auto mt-5 rounded-2xl">
                   </iframe>
                   <br/>
                   -- Model Links on thangs --
                <br/><a className="text-highlight-lime underline text-2xl" href="https://thangs.com/designer/andrewtho5942/3d-model/Side%20Hextraction%20Gutters%20--%20Basic%20Board-884874?source=Recent">
                    Basic board with side gutters </a>
                <br/><a className="text-highlight-lime underline text-2xl" href="https://thangs.com/designer/andrewtho5942/3d-model/Full%20Hextraction%20Gutters-883800">
                    Full board gutters </a>
                    <section id="custom-tiles"/>
                <br/><a className="text-highlight-lime underline text-2xl" href="https://thangs.com/designer/andrewtho5942/3d-model/Hextraction%20Expanded%20Board%20Full%20Gutters-901080">
                    Full extended board gutters </a>
            </div>


            <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                Custom Tiles
            </div>
            <div className="bg-gray-800 rounded-xl bg-opacity-90 py-6 text-center p-4 text-2xl font-semibold text-gray-300">
            <p className="w-full lg:w-3/4 mx-auto">
                These are all of the custom tiles that I've made for Hextraction, although there are hundreds more made by Zach Freedman and the rest of the community. 
                Each of the tile images has a link to it's respective thangs page where you can get a more detailed description and the STL file to print it.
            </p>
            <div className="grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-4/5 mx-auto gap-6 my-6">
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Catch Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Catch%20Tile-868729"><img src="\Hextraction\catch_tile.png" alt="catch tile" className="rounded-2xl outline-light  h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Fate Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Fate%20Tile-869236"><img src="\Hextraction\fate_tile.jpg" alt="fate tile" className="rounded-2xl outline-light h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Redraw Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Redraw%20Tile-872307"><img src="\Hextraction\redraw_tile.png" alt="redraw tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Storage Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/The%20Ultimate%20Hextraction%20Storage%20Box-885493"><img src="\Hextraction\storage_box.webp" alt="storage box" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> 1up Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/1up%20Tile-887119"><img src="\Hextraction\one_up_tile.webp" alt="one up tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Trickshot Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Trickshot%20Tile-887828"><img src="\Hextraction\trickshot_tile.jpg" alt="trickshot tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Clone Army Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Clone%20Army%20Tile-901310"><img src="\Hextraction\clone_army_tile.jpg" alt="clone army tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Player Mat </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Hextraction%20Player%20Mat-902426"><img src="\Hextraction\player_mat.jpg" alt="player mat" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Divert Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Divert%20Tile-908608"><img src="\Hextraction\divert_tile.jpg" alt="divert tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Redirect Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Redirect%20Tile-908996"><img src="\Hextraction\redirect_tile.jpg" alt="redirect tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
                <div className="transform transition-transform duration-200 hover:scale-105 ">
                    <div className="text-highlight-lime mb-2"> Rotate Tile </div>
                    <a href="https://thangs.com/designer/andrewtho5942/3d-model/Rotate%20Tile-910361"><img src="\Hextraction\rotate_tile.jpg" alt="rotate tile" className="rounded-2xl outline-light   h-80 w-full object-cover"></img></a>
                </div>
            </div>
               <div className="mx-5">
                <br/>
                
                <div className=" text-highlight-lime ">Also, my 1up tile was featured on one of Zach Freedman's following videos!</div>
            <iframe
                title="yt-video"
                className="mx-auto w-full md:w-3/4 rounded-2xl mt-5"
                height="650"
                src="https://www.youtube.com/embed/m99I05VJFLQ?si=GR52sK0lxJXeQRsA&amp;start=946"
                allow="fullscreen; web-share"
                >
            </iframe>
            </div>
            </div>
           
            <div className="h-24" />

        </section>
    );
}