import React from "react";

export default function Robotics() {
    return (
        <section className="md:mx-10 mt-5">
            <div className="my-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">
                <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white">
                    Robotics Projects
                </h1>
            </div>
            <section id="arobo7"/>
            <div className="my-4 text-2xl mb-4 font-semibold text-gray-300 bg-gray-800 bg-opacity-90 p-4 rounded-xl lg:w-3/4 w-full mx-auto text-center">
                This page is for all of my robotics projects -- this includes CAD, mechanics, electronics, and embedded programming. From VEX to ROS, I have
                done robotics since 8th grade and will definitely be adding more here in the future.
            </div>
            <div className="bg-highlight-lime opacity-70 mt-10 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                AROBO7
            </div>

            <div className="bg-gray-800 rounded-xl p-6 bg-opacity-90">

                <p className="text-white text-2xl mx-auto w-3/4 pl-2 md:w-2/3 mb-6">
                    &nbsp;&nbsp;&nbsp;&nbsp; An ongoing "full-stack" robotics project -- I am currently done with the 3D modeling, fabrication, and electronics.
                    More description to be added later.
                    In the meantime, feel free to view the CAD model that I made for it below!
                </p>
                <div className="mx-auto w-3/4">
                    <iframe
                        src="https://gmail3353691.autodesk360.com/shares/public/SHd38bfQT1fb47330c9987dd31c86f10dbfb?mode=embed"
                        allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" frameborder="0" title="AROBO7 CAD"
                        className="rounded-2xl w-full" height="600">

                    </iframe>
                    <section id="2630a"/>

                </div>
            </div>

            <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
                VEX 2630A - Tipping Point
            </div>
            <div className="bg-gray-800 rounded-xl p-6 bg-opacity-90">

                <p className="text-white text-2xl mx-auto w-3/4 pl-2 md:w-2/3 mb-6">
                    &nbsp;&nbsp;&nbsp;&nbsp; This robot competed in the 2022 VEX World championship for the game "Tipping Point".
                    Here is a brief explanation of the game:
                </p>
                <div className="w-4/5 mx-auto my-10">
                    <iframe
                        title="yt-video"
                        className="mx-auto w-full rounded-2xl"
                        height="650"
                        src="https://www.youtube.com/embed/H8XcvADUXTE?si=4hDh_PL6KFQcV9BA"
                        allow="fullscreen; web-share"
                    >
                    </iframe>
                </div>

                <p className="text-white text-2xl mx-auto w-3/4 pl-2 md:w-2/3 mb-6">
                    &nbsp;&nbsp;&nbsp;&nbsp; My team (4 people total) re-designed and re-built a robot that was faster, more efficient, and more competitive in this game
                    after we reached the #1 skills ranking in the state of Illinois with our previous robot. We did this by using a 6-bar lift mechanism, rather than a 4-bar, to lower our center of gravity
                    and by reducing our gear ratio to create a 360 RPM drive on 3" wheels. 6 wheels were used, connected by a gear train. The four outer wheels are omni wheels for optimal turning, while the
                    two center wheels are for traction and, most importantly, to prevent being pushed from the side by other robots.
                    Here are some pictures of the CAD I made for it:
                </p>
                <div className="grid md:grid-cols-2 sm:grid-cols-1 w-4/5 mx-auto gap-4 mb-6">
                    <img src="\TiP_Worlds_Bot_11.PNG" alt="TiP Robot with lift down" className="rounded-2xl outline-light "></img>
                    <img src="\TiP_Worlds_Bot_12.PNG" alt="TiP Robot with lift up" className="rounded-2xl outline-light"></img>
                </div>

                <p className="text-white text-2xl mx-auto w-3/4 pl-2 md:w-2/3 mb-6">
                    &nbsp;&nbsp;&nbsp;&nbsp; The robot used tensioned tracking wheels to know where it is on the field during autonomous control,
                    with the help of PID and odometry algorithms. It also had a locking-clamp on the front of the lift that is used to hold and
                    protect mobile goals from being stolen by other robots, activated with a pneumatic piston. The lift then allows the robot to
                    place the goals directly onto the ramp to score 40 points each.
                    <br></br><br></br>After completing the design of the robot, we made some fun finishing touches to it including laser-cutting polycarb sheets for the sides of the drivetrain,
                    spray-painting many parts of the robot red, and (my favorite part) adding red LED strips that are powered by a separate battery (we didn't want this to affect the main
                    battery life during matches). Here are some pictures of the finished robot!
                </p>
                <div className="grid md:grid-cols-2 sm:grid-cols-1 w-4/5 mx-auto gap-4 mb-6">

                    <img src="\Worlds_Bot_IRL3.jpg" alt="TiP Robot IRL with two goals, one lifted" className="rounded-2xl outline-light "></img>
                    <img src="\Worlds_Bot_IRL4.jpg" alt="TiP Robot IRL with two goals, both down" className="rounded-2xl outline-light "></img>
                    <img src="\Worlds_Bot_IRL5.jpg" alt="TiP Robot IRL front right side" className="rounded-2xl outline-light "></img>
                    <img src="\Worlds_Bot_IRL6.jpg" alt="TiP Robot IRL front left side" className="rounded-2xl outline-light "></img>
                    <img src="\Worlds_Bot_IRL1.JPEG" alt="TiP Robot IRL side" className="rounded-2xl outline-light "></img>
                    <img src="\Worlds_Bot_IRL2.JPEG" alt="TiP Robot IRL back" className="rounded-2xl outline-light "></img>
                </div>
                
                <p className="text-white text-2xl mx-auto w-3/4 pl-2 md:w-2/3 mb-6">
                    &nbsp;&nbsp;&nbsp;&nbsp; Lastly, here is a video of the robot working autonomously!
                </p>
                <div className="w-4/5 mx-auto my-10">
                    <iframe
                        title="yt-video"
                        className="mx-auto w-full rounded-2xl"
                        height="650"
                        src="https://www.youtube.com/embed/V62mvjtu13c?si=CY-JHkYiP43nJEpm"
                        allow="fullscreen; web-share"
                    >
                    </iframe>
                </div>
                <div className="mx-auto w-3/4">

                </div>
            </div>

        </section>
    );
}
