import React,  { useEffect, useState } from "react";

export default function Projects() {
    // set the event listeners for loading and resizing
    const [scaleFactorRCR, setScaleFactorRCR] = useState(1);
    const [scaleFactorTWHG, setScaleFactorTWHG] = useState(1);

    useEffect(() => {
        const handleResize = () => {
          console.log("resizing");
          //RCR
          let sfa = ((window.innerWidth*0.92) / 1600)
          let sfb = ((window.innerHeight*0.9) / 800)
          const newScaleFactora = Math.min(sfa, sfb);
          setScaleFactorRCR(newScaleFactora);

          //TWHG
          let sf1 = ((window.innerWidth * 1.22) / 800)
          let sf2 = ((window.innerHeight * 1.18) / 800)
          const newScaleFactor1 = Math.min(sf1, sf2);
          setScaleFactorTWHG(newScaleFactor1);
        };
        
        handleResize();
        window.addEventListener('resize', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      


    return (
    <section className="md:mx-10 mt-5">   
          <div className= "mt-10 mb-10 mx-auto bg-gray-800 bg-opacity-70 p-4 pb-2 rounded-xl lg:w-3/4 w-full text-center">    
    <h1 className="text-5xl md:text-6xl mb-4 font-bold text-white">
            My Projects
        </h1>
    </div>
    <section id ="sequence"/>
    <div className="my-4 text-2xl mb-4 font-semibold text-gray-300 bg-gray-800 bg-opacity-90 p-4 rounded-xl lg:w-3/4 w-full mx-auto text-center">
        This page is a collection of my personal projects, dating from 2021 (my senior year in highschool) to the present. Many of these are 
        directly embedded into the website, allowing you to interact with them!
    </div>
    <div className="bg-highlight-lime opacity-70 mt-10 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        Sequence Memorization Test
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90 ml-6 py-6">
        <div className="grid grid-cols-1 md:grid-cols-2">
            <p className="text-white text-left text-2xl my-auto ml-auto md:mr-4 pl-2 md:w-2/3">
                &nbsp;&nbsp;&nbsp;&nbsp;This project was created in May of 2022 as the final project for my AP Computer Science Principles class. 
                It is a sequence memorization test where a random sequence of numbers is generated each playthrough and the user must input the sequence correctly to continue. 
                <br/><br/>Try getting a score above 10! 
                <br/>My high score is 18!
            </p>
            <iframe Title="Sequence Test Window" className="w-full" width="380" height="620" src="https://studio.code.org/projects/applab/Zh0P3MlePK0kcmUUdq06lX9XXTLHaTFd3AZkkj0Pt7U/embed"></iframe>
        </div>
        <section id ="twhg"/>
    </div>

    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-3/4 mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        The World's Hardest Game v2
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90">
        <p className="text-white text-left text-2xl w-3/4 my-auto p-4 mx-auto">
            &nbsp;&nbsp;&nbsp;&nbsp;This is my first game and large project that I've created, from fall 2021 to spring 2022. 
            The game is a remake and expansion of a popular online game called the "The World's Hardest Game". The objective for the main gamemode is to reach the end of each level
            with a minimal amount of deaths (you die by touching the black obstacles) and collecting keys / points on the way. You need 3 keys to pass each level, and to unlock the 
            final level, all of the points must be collected. There is a tutorial that explains this in the game in more detail, as well as another gamemode called endless mode, where 
            the levels are randomly selected and the game will continue infinitely until you touch an obstacle. A leaderboard shows the top scores in this gamemode (and has live updates to a database), 
            and the color theme can be changed as well. 
            Both gamemodes have level selections that can be used to start at any level of your choice. Lastly, the game is complete with sound effects and a unique soundtrack for each level. 
            <br/><br/> Feel free to try it below -- I challenge you to get on the endless mode leaderboard!
        </p>
        <div  style = {{ width:`${(800 * scaleFactorTWHG) * 0.75}px`, height:`${(800 * scaleFactorTWHG) * 0.75}px` }} className="rounded-l mx-auto overflow-hidden">
            <iframe title="The World's Hardest Game Window" width="800" height="800" src="TWHG/index.html"
             style={{transform: `scale(${scaleFactorTWHG})`, transformOrigin: '0 0' }}/>
        </div>
        
        <div className="mx-auto text-center py-4">
        <section id ="rcr"/>

        <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/TWHGv2">
            &lt;Code for this project&gt;
        </a>
        </div>
    </div>
   
    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-full mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        River City Reborn
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90">
        <p className="text-white text-left text-2xl lg:w-3/4 mx-auto p-6 pb-0 mb-5">
            &nbsp;&nbsp;&nbsp;&nbsp; This project was created in the summer and fall of 2022 for the CESJ, a non-profit organization. 
            The game is used as an educational tool on their website to spread awareness of the "just third way", an economic model that 
            promotes investment and ownership for all. 
        </p>
        <div  style = {{ width: `${(1600 * scaleFactorRCR)}px`, height:`${(800 * scaleFactorRCR)}px`}} className="rounded-l mx-auto overflow-hidden">
            <iframe title="River City Reborn Game Window" width="1600" height="800" src="RCR/index.html"
             style={{transform: `scale(${scaleFactorRCR})`, transformOrigin: '0 0' }}/>
        </div>
        <div className="mx-auto text-center py-4">
        <section id ="morcc"/>
        <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/RCRdev">
            &lt;Code for this project&gt;
        </a>
        </div>
    </div>
    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-full mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        Morse Code Compiler (morcc)
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90">
        <p className="text-white text-left text-2xl lg:w-3/4 mx-auto p-6">
            &nbsp;&nbsp;&nbsp;&nbsp; This project translates any text file to and from morse code. If the text is C code, it will use the simple C Compiler
            that I made in a computer architecture course (Purdue CS250) to also compile the code into assembly. 
        </p>
        <div className="md:w-3/4 lg:w-2/3 mx-auto px-4">
            <img alt="morcc example" src="/morcc.png" classname="rounded-xl mx-auto outline-light mb-4"/>
        </div>
       
        <div className="mx-auto text-center py-4">
        <section id ="boiler-living"/>
        <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/morcc">
            &lt;Code for this project&gt;
        </a>
        </div>
    </div>
    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-full mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        BoilerLiving - Purdue Housing Website
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90">
        <p className="text-white text-left text-2xl lg:w-3/4 mx-auto p-6">
            &nbsp;&nbsp;&nbsp;&nbsp; This is a website that my team and I created for Boilermake X, an annual hackathon at Purdue. The website has a live chat with real-time updating reviews and ratings for
            each Purdue dorm hall using Google's firestore database on firebase.
        </p>
        <div className="md:w-3/4 lg:w-2/3 mx-auto px-4">
            <img alt="boilerliving example" src="/boilermake2023.png" classname="rounded-xl mx-auto outline-light mb-4"/>
        </div>
       
        <div className="mx-auto text-center py-4">
        <section id ="boiler-block"/>
        <a className="text-center text-highlight-lime underline text-2xl" href="https://stackblitz.com/edit/js-pbubw9?file=README.md,index.js">
            &lt;Code for this project&gt;
        </a>
        </div> 
    </div>
    <div className="bg-highlight-lime opacity-70 mt-20 lg:w-2/3 w-full mx-auto text-center rounded-xl text-4xl font-bold py-1 mb-1">
        BoilerBlock - Redirect Blocker Browser Extension
    </div>
    <div className="bg-gray-800 rounded-xl bg-opacity-90">
            <div className="grid md:grid-cols-2 grid-flow-col grid-cols-1">
                <div className=" sm:w-2/3 md:w-1/2 lg:w-1/3 ml-auto">
                    <img alt="boilerblock popup" src="/boilerblock1.png" className="rounded-xl mb-8 mt-4"/>   
                </div>
            
            <p className="text-white text-left text-2xl lg:w-3/4 mr-auto p-6">
                &nbsp;&nbsp;&nbsp;&nbsp; BoilerBlock is a browser extension made with google chrome's webrequest API that can block unwanted redirects from
                any website that the user chooses. The user can also add whitelists to allow redirects to go through but only for certain URLs. 
                This was made for Boilermake XI with two other Purdue students. 
            </p>
        </div>

        

        <div className="md:w-3/4 lg:w-2/3 mx-auto px-4">
            <img alt="boilerblock example" src="/boilermake2024.png" classname="rounded-xl"/>
        </div>
       
        <div className="mx-auto text-center py-4">
        <a className="text-center text-highlight-lime underline text-2xl" href="https://github.com/Andrewtho5942/boilerBlock">
            &lt;Code for this project&gt;
        </a>
        <br/>
        <a className="text-center text-highlight-lime underline text-2xl" href="https://devpost.com/software/boilerblock">
            &lt;Devpost for project explanation and more info&gt;
        </a>
        </div> 
    </div>
    <div className="h-24"/>
    </section>
    );
}
